import React from 'react'
import * as myStyles from './styles.module.scss'
import { LogosProps } from './types'
import Icon from '../../basics/icon'

const Logos = ({ logos, isLarge = false }: LogosProps) => {
    const styles: any = myStyles
    return (
        <div className={styles.c_logos} data-large-grid={isLarge}>
            <div className={styles.logos}>
                {logos.map((logo) => (
                    <div key={logo.toString()} className={styles.logo}>
                        <Icon className={styles.icon} icon={logo} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Logos
