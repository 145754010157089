import React from 'react'
import * as myStyles from './styles.module.scss'
import { HeroModuleProps } from './types'
import HeroContent from '../../components/hero-content'
import Logos from '../../components/logos'
import Wrapper from '../../basics/wrapper'
import VideoBlock from '../../components/video-block/component'
import useResponsive from '../../../hooks/useResponsive'
import { BREAKPOINTS } from '../../../constants/breakpoints'

const HeroModule = ({
    isLight = false,
    intro,
    logos,
    background,
    hasOverlay,
    ...props
}: HeroModuleProps) => {
    const styles: any = myStyles
    const isMobile = useResponsive({ threshold: BREAKPOINTS.md })

    return (
        <div className={styles.m_hero} data-background={isLight ? 'light' : 'dark'}>
            <div className={styles.animation_wrapper}>
                {background && (
                    <div className={styles.background}>
                        {hasOverlay && <div className={styles.overlay} />}
                        {background.video && (
                            <VideoBlock
                                className={styles['video-wrapper']}
                                src={background.video.src}
                                aspectRatio={isMobile ? '9/16' : '16/9'}
                                type={'video/mp4'}
                            />
                        )}
                        {background.image && (
                            <img
                                className={styles.image}
                                src={background.image.src}
                                alt={background.image.alt}
                            />
                        )}
                    </div>
                )}
                <div className={styles.content}>
                    <HeroContent {...props} />
                    {logos && (
                        <Wrapper className={styles.wrapper}>
                            <h5 className={styles.intro}>{intro}</h5>
                        </Wrapper>
                    )}
                </div>
            </div>
            {logos && <Logos logos={logos} />}
        </div>
    )
}

export default HeroModule
