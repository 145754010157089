import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import HeaderSEO from '../components/partials/HeaderSEO'
import CustomersPage from '../hanzo/pages/customers'

const customers = () => {
    const data = useStaticQuery(graphql`
        query CustomersQuery {
            allContentfulClientsLogos {
                edges {
                    node {
                        name
                        logo {
                            fluid {
                                src
                            }
                        }
                    }
                }
            }
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Customers" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout newsletter={false} linkColor={'dark'} isTransparent>
            <HeaderSEO pageSEO={pageSEO} />
            <section>
                <CustomersPage />
            </section>
        </Layout>
    )
}

export default customers
