export const BREAKPOINTS = {
    xxs: 0,
    xs: 375,
    sm: 480,
    md: 768,
    lg: 1020,
    xl: 1280,
    xxl: 1480,
    xxxl: 1920
}
