import React from 'react'
import * as myStyles from './styles.module.scss'
import HeroModule from '../../lib/components/modules/hero'
import {
    heroProps,
    preFooterTitle,
    cardsDataPrefooter,
    graphicsAndLogos,
    featuredStoriesCardsData,
    featuredStoriesTitle,
} from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import GraphicLogos from '../../lib/components/modules/graphic-logos'
import useResponsive from '../../lib/hooks/useResponsive'
import { BREAKPOINTS } from '../../lib/constants/breakpoints'
import backgroundImageMobile from '../../../resources/images/hanzo/testimonials/background_mobile.webp'
import backgroundImage from '../../../resources/images/hanzo/testimonials/background.webp'

const CustomersPage = () => {
    const styles: any = myStyles
    const isMobile = useResponsive({ threshold: BREAKPOINTS.md })

    return (
        <div className={styles.customers_page}>
            <div className={styles.content}>
                <HeroModule
                    {...heroProps}
                    background={{
                        image: {
                            src: isMobile ? backgroundImageMobile : backgroundImage,
                            alt: 'pattern',
                        },
                    }}
                />
                <PreFooter
                    title={featuredStoriesTitle}
                    cardsData={featuredStoriesCardsData}
                    background='light'
                    withoutPatternBg
                />
                <div className={styles.bg_grey_black}>
                    <GraphicLogos {...graphicsAndLogos} />
                </div>
                <div className={styles.bg_grey_20}>
                    <PreFooter title={preFooterTitle} cardsData={cardsDataPrefooter} />
                </div>
            </div>
        </div>
    )
}

export default CustomersPage
