import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import * as rawStyles from './styles.module.scss'
import { MarqueeProps } from './types'

const Marquee: React.FC<MarqueeProps> = ({ texts }) => {
    const marqueeRef = useRef<HTMLDivElement>(null)
    const styles: any = rawStyles
    useEffect(() => {
        if (marqueeRef.current) {
            const marqueeWidth = marqueeRef.current.scrollWidth
            gsap.fromTo(
                marqueeRef.current,
                { x: window.innerWidth },
                {
                    x: -marqueeWidth,
                    ease: 'none',
                    duration: 80,
                    repeat: -1,
                },
            )
        }
    }, [texts])

    return (
        <div className={styles.c_marquee}>
            <div className={styles.content} ref={marqueeRef}>
                <ul className={styles.text}>
                    {[...texts].map((text, index) => (
                        <li key={index}>
                            <span className={styles.review}>{`"${text.review}"`}</span>
                            <span className={styles.author}>{`${text.author}`}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Marquee
