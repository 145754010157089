import React from 'react'
import * as myStyles from './styles.module.scss'
import { GraphicLogosModuleProps } from './types'
import Logos from '../../components/logos'
import ClickableArea from '../../basics/clickable-area/component'
import Wrapper from '../../basics/wrapper'
import ReactHtmlParser from 'react-html-parser'
import VideoBlock from '../../components/video-block/component'

const GraphicLogos = ({
    title,
    logos,
    ctaText,
    ctaHref,
    graphicSrc,
    graphicAltText,
    video,
    pretitle,
    description,
}: GraphicLogosModuleProps) => {
    const styles: any = myStyles

    return (
        <div className={styles.m_graphic_logos} {...(video ? { 'data-is-video': true } : {})}>
            <Wrapper className={styles.wrapper}>
                <div className={styles.title}>
                    {pretitle && <div className={styles.pretitle}>{pretitle}</div>}
                    <h3>{title}</h3>
                    {description && (
                        <p className={styles.description}>{ReactHtmlParser(description)}</p>
                    )}
                </div>

                {ctaText && ctaHref && (
                    <div className={styles.cta_content}>
                        <ClickableArea
                            className={styles.cta}
                            variant='primary'
                            color='orange'
                            href={ctaHref}>
                            {ctaText}
                        </ClickableArea>
                    </div>
                )}
                {(graphicSrc || video) && (
                    <figure className={styles.media_content}>
                        {graphicSrc ? (
                            <img src={graphicSrc} alt={graphicAltText || 'graphic'} />
                        ) : (
                            video && (
                                <VideoBlock
                                    className={styles.video}
                                    src={video.src}
                                    type={'video/mp4'}
                                    typeOptimized={'video/webm'}
                                    fit={'contain'}
                                    srcOptimized={video.srcOptimized}
                                />
                            )
                        )}
                    </figure>
                )}
            </Wrapper>
            {logos && (
                <div className={styles.logos}>
                    <Logos logos={logos} isLarge={true} />
                </div>
            )}
        </div>
    )
}

export default GraphicLogos
