import React from 'react'
import * as rawStyles from './styles.module.scss'
import ClickableArea from '../../basics/clickable-area/component'
import { OptionCardProps } from './types'
import ReactHtmlParser from 'react-html-parser'
import useResponsive from '../../../hooks/useResponsive'
import { BREAKPOINTS } from '../../../constants/breakpoints'

const OptionCard: React.FC<OptionCardProps> = ({
    logo,
    title,
    description,
    icon,
    buttonText,
    href,
    onButtonClick,
    type = 'primary',
    isSmall = false,
}) => {
    const styles: any = rawStyles
    const Tag = type === 'tertiary' ? 'a' : 'div'
    const isMobile = useResponsive({ threshold: BREAKPOINTS.lg })

    return (
        <Tag className={styles.c_option_card} data-type={type} data-small={isSmall} href={href}>
            <div className={styles.info}>
                <div className={styles.text}>
                    {logo && <div className={styles.logo}>{logo}</div>}
                    <h2>{ReactHtmlParser(title)}</h2>
                    {description && description.map((line) => <p key={line}>{line}</p>)}
                </div>
                {icon && <div className={styles.icon}>{icon}</div>}
            </div>
            {buttonText && (href || onButtonClick) && (
                <div className={styles.action}>
                    <ClickableArea
                        variant={type === 'tertiary' ? 'secondary' : type}
                        color={
                            type === 'tertiary' ? 'dark' : type === 'primary' ? 'light' : 'orange'
                        }
                        href={href}
                        onClick={onButtonClick}
                        isFullWidth={isMobile}>
                        {buttonText}
                    </ClickableArea>
                </div>
            )}
        </Tag>
    )
}

export default OptionCard
