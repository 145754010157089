import React from 'react'
import * as myStyles from './styles.module.scss'
import { VideoBlockProps } from './types'

const VideoBlock = ({
    src,
    srcOptimized,
    className,
    muted = true,
    autoplay = true,
    controls = false,
    loop = true,
    type = 'video/mp4',
    typeOptimized = 'video/webm',
    aspectRatio = '9/16',
    fit,
    ...props
}: VideoBlockProps) => {
    const styles: any = myStyles
    return (
        <div
            className={`${styles['c_video_block']} ${className}`}
            data-aspect-ratio={aspectRatio}
            data-fit={fit && fit}
            data-testid='test_video_block'>
            <video
                playsInline
                id='video-id'
                className={styles['video_wrapper']}
                autoPlay={autoplay}
                loop={loop}
                muted={muted}
                controls={controls}
                {...props}>
                {srcOptimized && <source src={srcOptimized} type={typeOptimized} />}
                <source src={src} type={type} />
            </video>
        </div>
    )
}

export default VideoBlock
