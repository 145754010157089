import { useState, useEffect, useCallback } from 'react'
import { BREAKPOINTS } from '../constants/breakpoints'

type useResponsiveTypes = {
    threshold?: number
    onMobile?: () => void
    onDesktop?: () => void
}

/**
 * @description Custom hook returning if a mobile threshold has been tresspassed or not
 *
 * @param onMobile Callback executed when trespassing to mobile breakpoints
 * @param onDesktop Callback executed when trespassing to desktop breakpoints
 *
 * @returns isMobile boolean
 */
const useResponsive = ({
    threshold = BREAKPOINTS.md,
    onMobile,
    onDesktop,
}: useResponsiveTypes = {}) => {
    const [isBelowThreshold, setIsBelowThreshold] = useState(() => {
        if (typeof window !== 'undefined') {
            return window.matchMedia(`(max-width: ${threshold}px)`).matches
        }
        return false
    })

    const handleResize = useCallback(() => {
        if (typeof window === 'undefined') return

        const mediaQuery = window.matchMedia(`(max-width: ${threshold}px)`)
        if (mediaQuery.matches) {
            setIsBelowThreshold(true)
            if (onMobile) onMobile()
        } else {
            setIsBelowThreshold(false)
            if (onDesktop) onDesktop()
        }
    }, [onDesktop, onMobile, threshold])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const mediaQuery = window.matchMedia(`(max-width: ${threshold}px)`)
            setIsBelowThreshold(mediaQuery.matches)

            mediaQuery.addEventListener('change', handleResize)

            return () => {
                mediaQuery.removeEventListener('change', handleResize)
            }
        }
    }, [handleResize, threshold])

    return isBelowThreshold
}

export default useResponsive
